import React, { useState, useRef } from "react";
import QRCode from "qrcode.react";
import "./App.css";

function App() {
  const [ssid, setSSID] = useState("");
  const [password, setPassword] = useState("");
  const [qrValue, setQrValue] = useState("");
  const qrRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const wifiConfig = `WIFI:T:WPA;S:${ssid};P:${password};;`;
    setQrValue(wifiConfig);
  };

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "wifi-qr-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="App">
      <h1>WiFi QR Code Generator</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Network Name (SSID): </label>
          <input
            type="text"
            value={ssid}
            onChange={(e) => setSSID(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password: </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Generate QR Code</button>
      </form>
      {qrValue && (
        <div ref={qrRef}>
          <h2>Scan the QR code to connect:</h2>
          <QRCode value={qrValue} size={256} />
          <button onClick={handleDownload}>Download QR Code as PNG</button>
        </div>
      )}
    </div>
  );
}

export default App;
